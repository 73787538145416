import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonNav,
  IonMenu,
  IonContent,
  IonHeader,
  IonToolbar,
  IonList,
  IonItem,
  IonSplitPane,
  IonPage,
  IonButton,
  IonMenuButton,
  IonMenuToggle,
  IonButtons
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { apps, flash, send } from "ionicons/icons";
import Tab1 from "./components/Tab1";
import Tab2 from "./components/Tab2";
import Tab3 from "./components/Tab3";
import Details from "./components/Details";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import { link } from "ionicons/icons";
/* Theme variables */
import "./theme/variables.css";
import "./App.css";

const App: React.FC = () => (
  <IonApp>
    {/* Frame of App */}
    <IonHeader>
      <IonToolbar align-title="center">
        <IonButtons slot="start">
          <IonMenuButton autoHide={false}>
            <IonIcon name="menu" />
          </IonMenuButton>
        </IonButtons>
        <IonButton
          className="ion-button"
          fill="clear"
          router-direction="root"
          routerLink="/"
        >
          <img src={require("./assets/cusense_name.svg")}></img>
        </IonButton>
      </IonToolbar>
    </IonHeader>

    {/* Below the Frame */}
    <IonContent>
      {/* Menu */}
      <IonMenuToggle>
        <IonMenu
          side="start"
          menuId="menu"
          className="my-custom-menu"
          disabled={false}
          contentId="main"
          swipeGesture={false}
        >
          <IonHeader>
            <IonToolbar color="translucent">
              <IonTitle>Menu</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              <IonItem routerLink="/portal">
                <IonIcon icon={link} slot="start"></IonIcon>
                <IonLabel>API Portal</IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        </IonMenu>
      </IonMenuToggle>

      {/* Actual Content */}
      <IonPage id="main">
        <IonReactRouter>
          <IonRouterOutlet>
            {/* <Route path="/home" component={Tab1} exact={true} /> */}
            <Route
              path="/portal"
              component={() => {
                window.location.href = "https://cusense.net/portal";
                return null;
              }}
            />
            <Route path="/map" component={Tab2} exact={true} />
            <Route path="/map/details" component={Details} />
            {/* <Route path="/tab3" component={Tab3} /> */}
            <Route
              path="/"
              render={() => <Redirect to="/map" />}
              exact={true}
            />
          </IonRouterOutlet>
        </IonReactRouter>
      </IonPage>
    </IonContent>
  </IonApp>
);

export default App;
