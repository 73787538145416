import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonGrid,
  IonCol,
  IonButton,
  IonRow,
  IonSelect,
  IonItem,
  IonLabel,
  IonSelectOption,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonDatetime,
  IonIcon,
  IonRefresher,
} from "@ionic/react";
import "./Details.css";
import { getSensorColor, getColor, getFace, getWord } from "./ColorBar";
import Map from "./Tab2";
import {
  getSensorMonthHistory,
  getSensorDayHistory,
  getSensorRealTime,
} from "./API";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Route, Redirect } from "react-router";
import { arrowUp } from "ionicons/icons";

class Details extends React.Component<any, any> {
  datetimeRef: any;
  messagesEndRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      haveData: false,
      historyDay: "",
      hourlyButtonState: "",
      dailyButtonState: "",
      data: "",
      pm25: "",
      pm10: "",
      pm1: "",
      temp: "",
      humid: "",
      isGoButtonClick: false,
      dateSelected: "",
      dataTypeSelected: "",
      topic: "",
      redirect: "",
      selectedData: "",
      selectedInfo: "",
      // selectedKey: "",
      segmentSelected: true,
    };
    this.datetimeRef = React.createRef();
    this.messagesEndRef = React.createRef();
    // console.log("mess ref", this.messagesEndRef);
  }
  // handleRectClick = () => {
  //   this.scrollToBottom();
  // };
  componentWillReceiveProps(props: any) {
    this.setState({
      selectedData: props.selectedData,
      selectedInfo: props.selectedInfo,
      // selectedKey: props.selectedKey,
      data: "",
      pm25: "",
      pm10: "",
      pm1: "",
      temp: "",
      humid: "",
    });
    if (this.datetimeContainerRef != null) {
      this.datetimeContainerRef.value = "";
    }
  }
  componentDidMount() {}
  componentDidUpdate() {
    this.datetimeContainerRef = this.datetimeRef.current;
    this.messagesEnd = this.messagesEndRef.current;
    if (this.messagesEnd != null) {
      // console.log("in");
      this.scrollToBottom();
    }
  }
  convertTime = (rfc3339: any) => {
    const event = new Date(rfc3339);

    // British English uses day-month-year order and 24-hour time without AM/PM
    // console.log(event.toLocaleString("th-TH"));
    return event.toLocaleString("th-TH");
  };
  createDataCard() {
    // console.log("Details in its class", Details.selectedData);
    // let selectedData = this.state.selectedData;
    // let selectedInfo = this.state.selectedInfo;
    let pm25data = this.state.selectedData.pm25;
    let pm1data = this.state.selectedData.pm1;
    let pm10data = this.state.selectedData.pm10;
    let humidity = this.state.selectedData.humid;
    let tempdata = this.state.selectedData.temp;
    let loc_name = this.state.selectedInfo.name;
    let sensorColor = getSensorColor(pm25data);
    return (
      <IonCard className="welcome-card">
        <div
          className="rectangle"
          style={{ backgroundColor: getColor(sensorColor) }}
        >
          <img
            src={require("../assets/face_" + getFace(sensorColor) + ".svg")}
            className="smile"
          ></img>
          <div className="grid-row-rect">
            <div className="pm25">PM2.5</div>
            <div className="grid-column-rect">
              <div className="pm25-data">{pm25data}</div>
              <div className="pm25-measure">µg/m3</div>
            </div>
          </div>
          <div className="pm25-word">{getWord(sensorColor)}</div>
        </div>

        <div className="grid-container">
          <div
            className="grid-column"
            style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
          >
            <div className="grid-item" style={{ textAlign: "left" }}>
              PM1
            </div>
            <div className="grid-item">{pm1data}</div>
            <div className="grid-item" style={{ textAlign: "right" }}>
              µg/m3
            </div>
          </div>
          <div
            className="grid-column"
            style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
          >
            <div className="grid-item" style={{ textAlign: "left" }}>
              TEMP
            </div>
            <div className="grid-item">{tempdata}</div>
            <div className="grid-item" style={{ textAlign: "right" }}>
              ℃
            </div>
          </div>
          <div
            className="grid-column"
            style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
          >
            <div className="grid-item" style={{ textAlign: "left" }}>
              PM10
            </div>
            <div className="grid-item">{pm10data}</div>
            <div className="grid-item" style={{ textAlign: "right" }}>
              µg/m3
            </div>
          </div>
          <div
            className="grid-column"
            style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
          >
            <div className="grid-item" style={{ textAlign: "left" }}>
              HUMIDITY
            </div>
            <div className="grid-item">{humidity}</div>
            <div className="grid-item" style={{ textAlign: "right" }}>
              %
            </div>
          </div>
        </div>
      </IonCard>
    );
  }
  isEmpty(obj: any) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  getPerData = (
    historyDay: any,
    key: any,
    dataChosen: any,
    dataTypeSelected: any
  ) => {
    if (this.isEmpty(historyDay)) {
      return [];
    }
    const perData = [];
    for (var i = 0; i < historyDay[key].data.length; i++) {
      if (dataTypeSelected == "hourly") {
        perData.push({
          x: i,
          y: historyDay[key].data[i][dataChosen],
        });
      } else {
        if (i != 0) {
          var j = i + 1;
          perData.push({
            x: j,
            y: historyDay[key].data[i][dataChosen],
          });
        }
      }
    }
    // console.log("perData", perData);
    return perData;
  };
  getHistory(date: any, topic: any, dataTypeSelected: any) {
    // const topic = proj_name + "/" + key;
    if (dataTypeSelected == "hourly") {
      const data = getSensorDayHistory(date, topic).then((result) => {
        this.setState({
          historyDay: result,
          isLoaded: true,
          error: result.error,
        });
        const historyDay = this.state.historyDay;
        const data = [
          {
            pm25: this.getPerData(historyDay, topic, "pm25", dataTypeSelected),
            pm10: this.getPerData(historyDay, topic, "pm10", dataTypeSelected),
            pm1: this.getPerData(historyDay, topic, "pm1", dataTypeSelected),
            temp: this.getPerData(historyDay, topic, "temp", dataTypeSelected),
            humid: this.getPerData(
              historyDay,
              topic,
              "humid",
              dataTypeSelected
            ),
          },
        ];
        // console.log("historyDay", this.state.historyDay);
        if (this.state.error != "TypeError: Failed to fetch") {
          this.setState({
            data: data,
            haveData: true,
          });
          return data;
        }
        return data;
      });
      return data;
    } else {
      const data = getSensorMonthHistory(date, topic).then((result) => {
        this.setState({
          historyDay: result,
          isLoaded: true,
          error: result.error,
        });
        const historyDay = this.state.historyDay;
        const data = [
          {
            pm25: this.getPerData(historyDay, topic, "pm25", dataTypeSelected),
            pm10: this.getPerData(historyDay, topic, "pm10", dataTypeSelected),
            pm1: this.getPerData(historyDay, topic, "pm1", dataTypeSelected),
            temp: this.getPerData(historyDay, topic, "temp", dataTypeSelected),
            humid: this.getPerData(
              historyDay,
              topic,
              "humid",
              dataTypeSelected
            ),
          },
        ];
        // console.log("historyMonth", this.state.historyDay);
        if (this.state.error != "TypeError: Failed to fetch") {
          this.setState({
            data: data,
            haveData: true,
          });
          return data;
        }
        return data;
      });
      return data;
    }
  }

  setDataInState = (data: any) => {
    const pm25 = this.getPrepData(data[0].pm25);
    const pm10 = this.getPrepData(data[0].pm10);
    const pm1 = this.getPrepData(data[0].pm1);
    const temp = this.getPrepData(data[0].temp);
    const humid = this.getPrepData(data[0].humid);
    this.setState({
      pm25: pm25,
      pm10: pm10,
      pm1: pm1,
      temp: temp,
      humid: humid,
      isGoButtonClick: true,
    });
  };
  getPrepData = (array: any) => {
    for (var i in array) {
      array[i].x = parseInt(i);
    }
    const filtered = this.filterArray(array);
    return filtered;
  };
  filterArray(test_array: any) {
    var index = -1,
      arr_length = test_array ? test_array.length : 0,
      resIndex = -1,
      result = [];

    while (++index < arr_length) {
      var value = test_array[index].y;

      if (value) {
        result[++resIndex] = { x: test_array[index].x, y: value };
      }
    }
    return result;
  }
  setColorForChart = (dataArray: any) => {
    const colorArray = [];
    for (var i in dataArray) {
      colorArray.push(getColor(getSensorColor(dataArray[i].y)));
    }
    return colorArray;
  };
  getChartOptions = (
    pm25: any,
    pm10: any,
    pm1: any,
    temp: any,
    humid: any,
    dataTypeSelected: any
  ) => {
    let categories: string[] = [];
    if (dataTypeSelected == "daily") {
      categories = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ];
    } else {
      categories = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ];
    }
    let isPM25HaveData = true;
    let isPM10HaveData = true;
    let isPM1HaveData = true;
    let isHumidShown = false;
    let isTempShown = false;
    if (pm25.length == 0) {
      // console.log("no pm25");
      isPM25HaveData = false;
    }
    if (pm10.length == 0) {
      // console.log("no pm10");
      isPM10HaveData = false;
    }
    if (pm1.length == 0) {
      // console.log("no pm1");
      isPM1HaveData = false;
    }
    if (!isPM1HaveData && !isPM25HaveData && !isPM10HaveData) {
      if (temp.length == 0 && humid.length == 0) {
        return {};
      } else if (temp.length == 0 && humid.length != 0) {
        isHumidShown = true;
      } else if (temp.length != 0 && humid.length == 0) {
        isTempShown = true;
      } else {
        isHumidShown = true;
        isTempShown = true;
      }
    }
    // const pm25colorArray = this.setColorForChart(pm25);
    // const pm10colorArray = this.setColorForChart(pm10);
    // const pm1colorArray = this.setColorForChart(pm1);
    const options = {
      credits: {
        enabled: false,
      },
      chart: {
        zoomType: "xy",
      },
      title: {
        text: "",
      },
      yAxis: [
        {
          // Temperature yAxis
          labels: {
            format: "{value}",
            style: {
              color: "#f7a35c",
            },
          },
          title: {
            text: "Temperature (°C)",
            style: {
              color: "#f7a35c",
            },
          },
          opposite: true,
        },
        {
          // PM yAxis
          gridLineWidth: 0,
          title: {
            text: "Particulate Matter  (µg/m" + "<sup>" + "3" + "</sup>" + ")",
          },
          labels: {
            format: "{value}",
          },
        },
        {
          // Humidity yAxis
          gridLineWidth: 0,
          title: {
            text: "Humidity (%)",
            style: {
              color: "#8085e9",
            },
          },
          labels: {
            format: "{value}",
            style: {
              color: "#8085e9",
            },
          },
          opposite: true,
        },
      ],
      xAxis: {
        categories: categories,
        // crosshair: true
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: [
        {
          name: "PM2.5",
          data: pm25,
          yAxis: 1,
          tooltip: {
            valueSuffix: " µg/m" + "3".sup(),
          },
          // colors: pm25colorArray,
          // colorByPoint: true
          marker: {
            enabled: isPM25HaveData,
          },
          visible: isPM25HaveData,
        },
        {
          name: "PM10",
          data: pm10,
          yAxis: 1,
          tooltip: {
            valueSuffix: " µg/m" + "3".sup(),
          },
          // colors: pm10colorArray,
          // colorByPoint: true
          marker: {
            enabled: isPM10HaveData,
          },
          visible: isPM10HaveData,
        },
        {
          name: "PM1",
          data: pm1,
          yAxis: 1,
          tooltip: {
            valueSuffix: " µg/m" + "3".sup(),
          },
          // colors: pm1colorArray,
          // colorByPoint: true
          marker: {
            enabled: isPM1HaveData,
          },
          visible: isPM1HaveData,
        },
        {
          name: "Temperature",
          data: temp,
          yAxis: 0,
          tooltip: {
            valueSuffix: " °C",
          },
          marker: {
            enabled: isTempShown,
          },
          dashStyle: "shortdot",
          visible: isTempShown,
        },
        {
          name: "Humidity",
          data: humid,
          yAxis: 2,
          tooltip: {
            valueSuffix: " %",
          },
          marker: {
            enabled: isHumidShown,
          },
          dashStyle: "shortdot",
          visible: isHumidShown,
        },
      ],
    };
    return options;
  };

  createSegmentButtons = () => {
    return (
      <IonRow>
        <IonCol>
          <IonSegment
            // color="lightgrey"
            onIonChange={(e) => {
              this.setState({
                dataTypeSelected: e.detail.value!,
                dateSelected: "",
                data: "",
                segmentSelected: true,
              });
              this.datetimeContainerRef.value = "";
            }}
            // color="secondary"
          >
            <IonSegmentButton value="hourly">
              <IonLabel className="ion-label">Hourly</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="daily">
              <IonLabel className="ion-label">Daily</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonCol>
      </IonRow>
    );
  };
  getToday(dataType: any) {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    if (dataType == "hourly") return yyyy + "-" + mm + "-" + dd;
    else return yyyy + "-" + mm;
  }
  datetimeContainerRef: any;
  createDateSelects = (dataType: any) => {
    var todayDate = this.getToday(dataType);
    const hourlyDate = (
      <IonCol>
        <IonItem>
          <IonDatetime
            ref={this.datetimeRef}
            placeholder="Select Date"
            min="2020-01-01"
            max={todayDate}
            displayFormat="DD MMM YYYY"
            className="ion-picker"
            onIonChange={(e) => {
              this.setState({
                dateSelected: e.detail.value!.slice(0, 10),
                data: "",
              });
            }}
          ></IonDatetime>
        </IonItem>
      </IonCol>
    );
    const monthlyDate = (
      <IonCol>
        <IonItem>
          <IonDatetime
            ref={this.datetimeRef}
            placeholder="Select Date"
            min="2020-01"
            max={todayDate}
            className="ion-picker"
            displayFormat="MMM YYYY"
            onIonChange={(e) => {
              this.setState({
                dateSelected: e.detail.value!.slice(0, 7),
                data: "",
              });
            }}
          ></IonDatetime>
        </IonItem>
      </IonCol>
    );
    if (dataType == "hourly") return hourlyDate;
    else return monthlyDate;
  };
  createGoButton(date: any, dataTypeSelected: any, topic: any) {
    if (date == "" || dataTypeSelected == "") {
      return (
        <IonButton disabled={true} shape="round">
          <IonIcon name="arrow-forward" />
        </IonButton>
      );
    } else
      return (
        <IonButton
          disabled={false}
          shape="round"
          onClick={(e) => {
            this.getHistory(date, topic, dataTypeSelected).then((data) => {
              if (data != undefined) {
                this.setDataInState(data);
              }
            });
          }}
        >
          <IonIcon name="arrow-forward" />
        </IonButton>
      );
  }
  createHistoryCard(pm25: any, pm10: any, pm1: any, temp: any, humid: any) {
    const options = this.getChartOptions(
      pm25,
      pm10,
      pm1,
      temp,
      humid,
      this.state.dataTypeSelected
    );
    return (
      <>
        <IonCardHeader className="ion-card-header">
          <IonCardTitle className="ion-card-title">
            {this.state.selectedInfo
              ? this.state.selectedInfo.name + ""
              : "Please click Back and select a sensor before proceed"}

            <IonCardSubtitle className="ion-card-subtitle">
              {this.state.selectedInfo
                ? "Please select timeframe and date"
                : ""}
            </IonCardSubtitle>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent className="ion-card-content">
          <IonGrid fixed={true}>
            {this.createSegmentButtons()}
            <IonRow>
              {/* <IonCol */}
              {/* // size="2"
              > */}
              {this.state.segmentSelected
                ? this.createDateSelects(this.state.dataTypeSelected)
                : ""}
              {/* </IonCol> */}
              {/* <IonCol className=""> */}

              {this.createGoButton(
                this.state.dateSelected,
                this.state.dataTypeSelected,
                this.state.selectedInfo.topic

                // "Dustboy/5080"
                // ||this.state.topic
              )}
              {/* </IonCol> */}
              {/* {console.log("dateSelected", this.state.dateSelected)}
              {console.log("dataTypeSelected", this.state.dataTypeSelected)} */}
            </IonRow>{" "}
          </IonGrid>
          {this.state.data && !this.isEmpty(options) ? (
            <HighchartsReact highcharts={Highcharts} options={options} />
          ) : (
            ""
          )}
          {this.isEmpty(options) && this.state.data ? (
            <div className="error-message">Sorry, no data available</div>
          ) : (
            ""
          )}
        </IonCardContent>
      </>
    );
  }
  messagesEnd: any;
  scrollToBottom = () => {
    if (this.messagesEnd == null) {
      this.forceUpdate();
      setTimeout(() => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
      }, 500);
    } else {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };
  // const Details: React.FC = () => {
  render() {
    // if (this.selectedData != null) {
    //   this.setState({ haveData: true });
    // }
    // if (this.state.redirect) {
    //   return <Redirect push to="/" exact={true} />;
    // }
    return this.state.selectedInfo ? (
      <>
        <IonCard>
          {/* <div style={{ height: "95vh" }}> */}
          {this.createHistoryCard(
            this.state.pm25,
            this.state.pm10,
            this.state.pm1,
            this.state.temp,
            this.state.humid
          )}
          <IonButtons>
            <IonButton
              color="dark"
              // router-direction="root"
              // routerLink="/"
              className="back-button"
              onClick={(e) => {
                this.props.handleBackButton();
              }}
            >
              <IonIcon icon={arrowUp} style={{ paddingRight: "5px" }} />
              Back To Top
            </IonButton>
          </IonButtons>
          <div
            style={{ float: "left", clear: "both" }}
            ref={this.messagesEndRef}
          ></div>
          {/* </div> */}
        </IonCard>
      </>
    ) : (
      ""
    );
    // : console.log("API is underdevelopment")

    // {/* </IonContent> */}
    // </IonPage>
  }
}
export default Details;
