export function getSensorColor(pm25: number) {
  if (pm25 == null) {
    return "grey";
  } else if (pm25 >= 0 && pm25 <= 15) {
    return "blue";
    // } else if (pm25 > 30 && pm25 <= 50) {
    //   return "yellow";
  } else if (pm25 > 15 && pm25 <= 25) {
    return "green";
  } else if (pm25 > 25 && pm25 <= 37.5) {
    return "yellow";
  } else if (pm25 > 37.5 && pm25 <= 75) {
    return "orange";
  } else if (pm25 > 75) {
    return "red";
  } else return "";
}

export function getColor(sensorColor: string) {
  if (sensorColor == "blue") {
    return "#19bbf7";
  } else if (sensorColor == "green") {
    return "#88b868";
  } else if (sensorColor == "yellow") {
    return "#d9d511";
  } else if (sensorColor == "orange") {
    return "#ffbf00";
  } else if (sensorColor == "red") {
    return "#dc1010";
  } else if (sensorColor == "grey") {
    return "lightgrey";
  } else return "";
}

export function getFace(sensorColor: string) {
  if (sensorColor == "blue") {
    return "smile";
  } else if (sensorColor == "green") {
    return "smile";
  } else if (sensorColor == "yellow") {
    return "nosmile";
  } else if (sensorColor == "orange") {
    return "sad";
  } else if (sensorColor == "red") {
    return "verysad";
    // } else if (sensorColor == "purple") {
    //   return "toxic";
  } else return "nosmile";
}

// Old Wordings

// export function getWord(sensorColor: string) {
//   if (sensorColor == "green") {
//     return "Good";
//   } else if (sensorColor == "yellow") {
//     return "Moderate";
//   } else if (sensorColor == "orange") {
//     return "Unhealthy for Sensitive Groups";
//   } else if (sensorColor == "red") {
//     return "Unhealthy";
//   } else if (sensorColor == "dark-red") {
//     return "Very Unhealthy";
//   } else if (sensorColor == "purple") {
//     return "Harzardous";
//   } else return "";
// }

export function getWord(sensorColor: string) {
  if (sensorColor == "blue") {
    return "Excellent";
  } else if (sensorColor == "green") {
    return "Satisfactory";
  } else if (sensorColor == "yellow") {
    return "Moderate";
  } else if (sensorColor == "orange") {
    return "Unhealthy";
  } else if (sensorColor == "red") {
    return "Very Unhealthy";
    // } else if (sensorColor == "purple") {
    //   return "Harzardous";
  } else return "";
}
