export function getSensorRealTime() {
  return fetch(
    // "http://161.200.80.206:3333/api/v1/sensorData/realtime/all"
    "https://www.cusense.net:8082/api/v1/sensorData/realtime/all",
    {
      method: "GET",
      headers: {
        "X-Gravitee-Api-Key": "e57dcdef-0c2f-452a-93e4-eaa261ab880d"
      }
    }
  )
    .then(res => res.json())
    .then(
      result => {
        return result;
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        return { isLoaded: true, error };
      }
    );
}

export function getSensorDayHistory(date: any, topic: any) {
  return fetch(
    // "http://161.200.80.206:3333/api/v1/sensorData/realtime/all"
    "https://www.cusense.net:8082/api/v1/sensorData/byStation/daily/" + date,
    {
      method: "POST",
      headers: {
        "X-Gravitee-Api-Key": "e57dcdef-0c2f-452a-93e4-eaa261ab880d",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ topic: topic }),
      redirect: "follow"
    }
  )
    .then(res => res.json())
    .then(
      result => {
        // console.log(result);
        return result;
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        return { isLoaded: true, error };
      }
    );
}

export function getSensorMonthHistory(month: any, topic: any) {
  return fetch(
    // "http://161.200.80.206:3333/api/v1/sensorData/realtime/all"
    "https://www.cusense.net:8082/api/v1/sensorData/byStation/monthly/" + month,
    {
      method: "POST",
      headers: {
        "X-Gravitee-Api-Key": "e57dcdef-0c2f-452a-93e4-eaa261ab880d",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ topic: topic }),
      redirect: "follow"
    }
  )
    .then(res => res.json())
    .then(
      result => {
        // console.log("month result", result);
        return result;
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      error => {
        return { isLoaded: true, error };
      }
    );
}
