import React, { useRef, CSSProperties, Component } from "react";
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonRouterOutlet,
  useIonViewDidEnter,
} from "@ionic/react";
import "./Tab2.css";
import L from "leaflet";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  LayerGroup,
} from "react-leaflet";
import Control from "react-leaflet-control";
// import DivIcon from "react-leaflet-div-icon";
// import Marker  from "react-leaflet-enhanced-marker";
import "leaflet/dist/leaflet.css";
import "leaflet-pulse-icon/src/L.Icon.Pulse.css";
import { Redirect, Route } from "react-router";
import { getSensorColor, getColor, getFace, getWord } from "./ColorBar";
import { getSensorRealTime } from "./API";
import Details from "./Details";
import { IonReactRouter } from "@ionic/react-router";
import "leaflet-easybutton";

var classNames = require("classnames");
var myIcon = L.icon({
  iconUrl:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII=",
  iconSize: [16, 25],
  iconAnchor: [8, 25],
  popupAnchor: [0, -25],
});

// class Legend extends MapControl {
//   componentDidMount() {
//      // place your code here

//      ...
//      const legend = L.control({ position: "bottomright" });

//      ...
//      const { map } = this.props.leaflet;
//      legend.addTo(map);
//   }
// }

class Tab2 extends React.Component<any, any> {
  // selectedData: any;
  // selectedInfo:any;
  myRef: any;
  map: any;
  focusListener: any;
  contentContainerRef: any;
  DetailsRef: any;
  // messagesEndRef: any;
  constructor(props: any) {
    super(props);
    this.contentRef = React.createRef();
    this.myRef = React.createRef();
    this.DetailsRef = React.createRef();
    // this.messagesEndRef = React.createRef();
    this.state = {
      location: { lat: 13, lng: 100 },
      haveUsersLocation: false,
      zoom: 6,
      redirect: false,
      error: null,
      items: null,
      isLoaded: false,
      selectedData: null,
      selectedInfo: null,
      haveData: false,
      route: "",
      direction: "",
      showLocationMarker: false,
    };
    this.handleBackButton = this.handleBackButton.bind(this);

    // this.handleScrollToBottom = this.handleScrollToBottom.bind(this)
  }

  handleBackButton = () => {
    this.scrollToTop();
  };

  resetDetailsData = () => {};

  scrollToTop = () => {
    this.contentContainerRef.scrollToTop(500);
  };

  handleRectClick = (selectedData: any, selectedInfo: any) => {
    this.setState({
      selectedData: selectedData,
      selectedInfo: selectedInfo,
    });
    this.resetDetailsData();
    this.DetailsRef.current.scrollToBottom();
  };

  componentDidMount() {
    // const navigation = this.props;
    //Adding an event listner om focus
    //So whenever the screen will have focus it will set the state to zero
    // this.focusListener = this.props.addListener("didFocus", () => {
    //   this.setState({ count: 0 });
    // });
    // window.location.reload(true);
    this.contentContainerRef = this.contentRef.current;
    // this.messagesEnd = this.messagesEndRef.current;
    this.map = this.myRef.current;
    const mapElement = this.map.leafletElement;
    setTimeout(function () {
      mapElement.invalidateSize();
    }, 1500);
    mapElement.attributionControl.addAttribution("CUSense PM2.5 Map");
    this.getSensorData();
  }

  getUserLocation() {
    if (!this.state.haveUsersLocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            location: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            haveUsersLocation: true,
            // zoom: 13,
          });
        },
        //if can't get user's location
        () => {
          // console.log("uh oh...they did not give us their location...");
          fetch("https://ipapi.co/json")
            .then((res) => res.json())
            .then((location) => {
              this.setState({
                location: {
                  lat: location.latitude,
                  lng: location.longitude,
                },
                haveUsersLocation: true,
                zoom: 13,
              });
            });
        }
      );
    }
    // this.setState({ zoom: 13 });
  }
  componentWillUnmount() {
    // Remove the event listener before removing the screen from the stack
    // this.focusListener.remove();
  }
  getSensorData = () => {
    getSensorRealTime().then((result) => {
      this.setState({
        items: result,
        isLoaded: true,
        error: result.error,
      });
      // console.log(this.state.items);
      if (this.state.error != "TypeError: Failed to fetch") {
        this.setState({
          haveData: true,
          // zoom: 6
        });
      }
    });
  };
  // selectedKey: any;
  checkNull = (data: any) => {
    if (data != null) {
      return data;
    } else return "-";
  };
  convertTime = (rfc3339: any) => {
    const event = new Date(rfc3339);

    // British English uses day-month-year order and 24-hour time without AM/PM
    // console.log(event.toLocaleString("th-TH"));
    return event.toLocaleString("th-TH");
  };
  handleLable = (label: any, sensors: any) => {
    // console.log("handle lable");
    for (var i = 0; i < sensors.length; i++) {
      if (i == label) {
        return sensors.key;
      }
      return "";
    }
  };

  getProjectNameInfo = (proj_name: any) => {
    if (proj_name == "PCD") {
      return "(Past 24hrs Average)";
    } else if (proj_name == "Dustboy") {
      return "(Past 1hr Average)";
    } else return;
  };

  getProjects = (sensors: any) => {
    let projects = [];
    for (var key in sensors) {
      const proj_name = sensors[key].info.project;
      projects.push(proj_name);
    }
    return Array.from(new Set(projects));
  };

  createSensorPointers = (sensors: any) => {
    // CircleMarkers = [];
    let projects = this.getProjects(sensors);
    let LayerMarkers = [];
    for (var i = 0; i < projects.length; i++) {
      let CircleMarkers: JSX.Element[] = [];
      for (var key in sensors) {
        //Location Data
        const lat = sensors[key].info.lat;
        const lng = sensors[key].info.lon;
        var position = L.latLng(lat, lng);

        //Sensor Data
        const pm25data = sensors[key].data[0].pm25;
        const pm1data = sensors[key].data[0].pm1;
        const pm10data = sensors[key].data[0].pm10;
        const tempdata = sensors[key].data[0].temp;
        const humidity = sensors[key].data[0].humid;
        const last_updated = sensors[key].data[0].time;
        const loc_name = sensors[key].info.name;
        const proj_name = sensors[key].info.project;

        //Sensor color
        const sensorColor = getSensorColor(sensors[key].data[0].pm25);
        let classes = {
          ["marker"]: true,
          [sensorColor]: true,
        };

        //Default circle marker icon
        const text = L.divIcon({
          className: classNames(classes),
          html: pm25data,
          // fix marker moving when zoom bug
          iconAnchor: [14, 20],
          iconSize: [28, 40],
        });

        if (proj_name == projects[i].toString()) {
          CircleMarkers.push(
            <Marker position={position} icon={text}>
              <Popup
                className="leaflet-popup-content-wrapper"
                offset={[0.9, -10]
                  
                }
              >
                <div className="location">{loc_name}</div>
                {
                  // sensorColor != "grey" &&
                  sensorColor != null ? (
                    <div>
                      <div
                        id={key}
                        className="rectangle"
                        style={{ backgroundColor: getColor(sensorColor) }}
                        //handle click to Details page
                        onClick={(e) => {
                          // if (this.selected == false) {
                          var id = e.currentTarget.id;
                          // const queryKey = this.handleLable(id, sensors);
                          this.handleRectClick(
                            sensors[id].data[0],
                            sensors[id].info
                            // id
                          );
                          // this.selected = true;
                          // this.setState({ items: "" });
                          // this.getSensorData();
                          this.scrollToBottom();
                          // }
                          //   this.selectedKey = key;
                          // }
                          // CircleMarkers[i];
                        }}
                      >
                        {sensorColor != "grey" ? (
                          <img
                            src={require("../assets/face_" +
                              getFace(sensorColor) +
                              ".svg")}
                            // height="45vw"
                            className="smile"
                          ></img>
                        ) : (
                          <div
                            className="smile"
                            style={{ marginLeft: 0, width: "0px" }}
                          />
                        )}

                        <div className="grid-row-rect">
                          <div className="pm25">
                            <b>PM2.5</b>
                          </div>
                          <div className="grid-column-rect">
                            <div className="pm25-data">
                              {this.checkNull(pm25data)}
                            </div>
                            <div className="pm25-measure">
                              µg/m<sup>3</sup>
                            </div>
                          </div>
                        </div>
                        {
                        // getWord(sensorColor) ==
                        // "Very Unhealthy" ? (
                        //   <div className="pm25-word">
                        //     {getWord(sensorColor)}
                        //   </div>
                        // ) : 
                        getWord(sensorColor) == "Very Unhealthy" ? (
                          <div
                            className="pm25-word"
                            style={{
                              padding: "8px 0 0 6px",
                            }}
                          >
                            {getWord(sensorColor)}
                          </div>
                        ) : (
                          <div
                            className="pm25-word"
                            style={{
                              padding: "13px 0 0 6px",
                            }}
                          >
                            {getWord(sensorColor)}
                          </div>
                        )}
                      </div>
                      <div className="grid-container">
                        <div
                          className="grid-column"
                          // style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
                        >
                          <div
                            className="grid-item"
                            style={{ textAlign: "left" }}
                          >
                            PM1
                          </div>
                          <div className="grid-item">
                            {this.checkNull(pm1data)}
                          </div>
                          <div
                            className="grid-item"
                            style={{ textAlign: "right" }}
                          >
                            µg/m<sup>3</sup>
                          </div>
                        </div>
                        <div
                          className="grid-column"
                          // style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
                        >
                          <div
                            className="grid-item"
                            style={{ textAlign: "left" }}
                          >
                            TEMP
                          </div>
                          <div className="grid-item">
                            {this.checkNull(tempdata)}
                          </div>
                          <div
                            className="grid-item"
                            style={{ textAlign: "right" }}
                          >
                            ℃
                          </div>
                        </div>
                        <div
                          className="grid-column"
                          // style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
                        >
                          <div
                            className="grid-item"
                            style={{ textAlign: "left" }}
                          >
                            PM10
                          </div>
                          <div className="grid-item">
                            {this.checkNull(pm10data)}
                          </div>
                          <div
                            className="grid-item"
                            style={{ textAlign: "right" }}
                          >
                            µg/m<sup>3</sup>
                          </div>
                        </div>
                        <div
                          className="grid-column"
                          // style={{ fontSize: "calc(0.75vw + 0.75vh + 0.25vmin)" }}
                        >
                          <div
                            className="grid-item"
                            style={{ textAlign: "left" }}
                          >
                            HUMIDITY
                          </div>
                          <div className="grid-item">
                            {this.checkNull(humidity)}
                          </div>
                          <div
                            className="grid-item"
                            style={{ textAlign: "right" }}
                          >
                            %
                          </div>
                        </div>
                      </div>
                      <div className="text-center" style={{ fontSize: "11px" }}>
                        Data provided by {proj_name}{" "}
                        {this.getProjectNameInfo(proj_name)}
                      </div>
                      <div className="text-center">
                        Last updated at {this.convertTime(last_updated)}
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                }
              </Popup>
            </Marker>
          );
        }
      }
      LayerMarkers.push(
        <LayersControl.Overlay name={projects[i].toString()} checked>
          <LayerGroup>{CircleMarkers}</LayerGroup>
        </LayersControl.Overlay>
      );
      CircleMarkers = [];
    }
    return LayerMarkers;
  };

  // showLegend() {
  //   var info = L.Control();

  //   info.onAdd = function (map) {
  //     this._div = L.DomUtil.create("div", "info"); // create a div with a class "info"
  //     this.update();
  //     return this._div;
  //   };
  // }

  // updateLeafletMap() {
  //   this.mapElement.invalidateSize();
  // }

  useIonViewWillEnter = () => {
    // console.log("ionViewDidEnter event fired");
    // console.log("ionViewDidEnter event fired");
  };
  contentRef: any;
  scrollToBottom = () => {
    // @ts-ignore
    this.contentContainerRef.scrollToBottom(500);
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    // if (this.state.redirect) {
    //   return (
    // <IonPage>
    //   <Route
    //     path={"/" + this.state.selectedInfo.topic}
    //     component={Details}
    //   />
    //   <Redirect push to={"/" + this.state.selectedInfo.topic} />
    // </IonPage>
    // <Redirect push to="/map/details" />;
    //   );
    // }
    var position = L.latLng(this.state.location.lat, this.state.location.lng);

    return (
      <IonPage>
        <IonContent ref={this.contentRef} scrollEvents={true}>
          <IonCard className="transparent-card">
            <div style={{ height: "88vh" }}>
              <Map
                ref={this.myRef}
                className="map"
                center={position}
                zoom={this.state.zoom}
              >
                <LayersControl position="topright">
                  <LayersControl.BaseLayer name="Streets" checked>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      detectRetina={true}
                      crossOrigin={true}
                      maxZoom={100}
                      useCache={true}
                      maxNativeZoom={18}
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Greyscale">
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                    />
                  </LayersControl.BaseLayer>

                  {this.state.haveData
                    ? this.createSensorPointers(this.state.items)
                    : ""}

                  {/* {console.log(this.state.items)} */}
                  {/* <LayersControl.Overlay name="Your Location" checked> */}
                  {this.state.haveUsersLocation &&
                  this.state.showLocationMarker ? (
                    <Marker
                      position={position}
                      icon={myIcon}
                      zIndexOffset={100}
                    >
                      <Popup className="popup-location">You are Here!</Popup>
                    </Marker>
                  ) : (
                    ""
                  )}
                  {/* </LayersControl.Overlay> */}
                </LayersControl>
                {
                  <Control position="bottomright">
                    <div className="info legend">
                      <div style={{ textAlign: "center" }}>
                        <b>Legend</b>
                      </div>
                      <i style={{ background: "#19bbf7" }}></i>0-15.0 µg/m
                      <sup>3</sup>
                      <br />
                      <i style={{ background: "#88b868" }}></i>15.1-25.0 µg/m
                      <sup>3</sup>
                      <br />
                      <i style={{ background: "#d9d511" }}></i>25.1-37.5 µg/m
                      <sup>3</sup>
                      <br />
                      <i style={{ background: "#ffbf00" }}></i>37.6-75.0 µg/m
                      <sup>3</sup>
                      <br />
                      <i style={{ background: "#dc1010" }}></i>75.1+ µg/m
                      <sup>3</sup>
                      <br />
                    </div>
                  </Control>
                }

                {/* <Control position="topleft">
                  <button
                    className="pin-button"
                    onClick={() => {
                      if (this.state.showLocationMarker) {
                        this.setState({
                          showLocationMarker: false,
                          // zoom: 6,
                        });
                      } else {
                        this.setState({ showLocationMarker: true });
                        this.getUserLocation();
                      }
                      // this.scrollToTop();
                    }}
                  >
                    <img
                      src={require("../assets/pin_icon.svg")}
                      className="pin-button-img"
                    ></img>
                  </button>
                </Control> */}
                {/* <Control position="topleft">{""}</Control>{" "} */}
                {/* {console.log("ref", this.map ? this.map : "no ref")}

                {this.myRef.current
                  ? this.myRef.current.container
                    ? L.easyButton(
                        '<img src="../assets/pin_icon.svg">',
                        function (btn, map) {
                          // var antarctica = [-77,70];
                          // map.setView(antarctica);
                        }
                        // "Question!",
                        // this.myRef.current
                      ).addTo(this.map)
                    : ""
                  : ""} */}
                {}
                {/* <Control position="bottomright">
                  <button
                    onClick={() => this.setState({ bounds: [51.3, 0.7] })}
                  >
                    Reset View
                  </button>
                  
                  
                </Control> */}
              </Map>
            </div>
          </IonCard>
          <Details
            ref={this.DetailsRef}
            handleBackButton={this.handleBackButton}
            selectedData={this.state.selectedData}
            selectedInfo={this.state.selectedInfo}
            // selectedKey={this.state.selectedKey}
          />
        </IonContent>
      </IonPage>
    );
  }
}

export default Tab2;
